import React from 'react';

function About() {
  return (
  <div className='' id="about">


<div className='heading'>
    <h2>ABOUT ME</h2>
    <span>Know Me More<hr /></span>
 </div>

<div className='container'>
 <div className='row about'>  
<div className='col-md-6'>
  <h2>Who is this man ?</h2>
<div className='my-info'>
  <p>Prem has a <span>strong vision</span> about the dynamic industry of <span>web designing</span> and <span>development</span> focusing on user’s interface and experience. It has been his mission over the years to adapt, collaborate, and build <span>amazing work relationships</span> with clients that enriches his <span>work ethics</span> and design techniques.</p>
<p>He has been a firm believer that all his experiences put together have given him a greater depth of <span>business-related knowledge</span> and makes him a better <span>front-end developer</span>. He moved to New Zealand to enhance his knowledge on IT by studying a <span>post-graduate diploma</span>.</p>
<p>He loves to work in teams and do <span>collaborative approach</span> on projects. However, you can find him challenging himself as well by working on his own and setting his own goals. He values <span>aesthetic</span> and have a keen eye for detail and design.</p>
</div>
</div>

<div className="col-md-6 col-sm-12 col-xs-12">
        	<div className="timeline-holder"  data-aos="fade-up" data-aos-easing="ease" data-aos-delay="400">
                    <div className="timeline-head">
                      <div className="icon">
                      <i class="bi bi-person"></i>
                      </div>
                    </div>
                    <div className="timeline-body">
                      <div className="profile-item timeline-item">
                        <div className="icon-item left">
                          <div className="circular-icon ">
                          <i class="bi bi-person-badge"></i>
                          </div>
                        </div>

                        <div className="profile-content timeline-content right">
                          <label>Full Name</label>
                          <div className="value">Yengkhom Prem Singha</div>
                        </div>

                      </div>

                      <div className="profile-item timeline-item">
                        <div className="event-content timeline-content left">
                         <label>Email Address</label>
                          <div className="value">y.prem.singha@gmail.com</div>
                        </div>
                           <div className="icon-item right">
                          <div className="circular-icon">
                          <i class="bi bi-envelope"></i>
                          </div>

                        </div>

                      </div>

                      <div className="profile-item timeline-item">
                        <div className="icon-item left">
                          <div className="circular-icon">
                          <i class="bi bi-egg"></i>
                          </div>

                        </div>
                        <div className="profile-content timeline-content right">
                          <label>Birthday</label>
                          <div className="value">06-Jan-1990</div>
                        </div>

                      </div>


                      <div className="profile-item timeline-item">
                        <div className="event-content timeline-content left">           
                           <label>Phone</label>
                          <div className="value">(+64) 022 322 6330</div>
                        </div>
                           <div className="icon-item right">
                          <div className="circular-icon">
                          <i class="bi bi-telephone"></i>
                          </div>

                        </div>

                      </div>


                      <div className="profile-item timeline-item">
                        <div className="icon-item left">
                          <div className="circular-icon left">
                          <i class="bi bi-code-slash"></i>
                          </div>

                        </div>
                        <div className="profile-content timeline-content right">
                          <label>Experience</label>
                          <div className="value">6+ Years</div>
                        </div>

                      </div>
                    </div>
                  </div>
</div>

 </div>
    

</div>
  </div>
  )
}

export default About;
