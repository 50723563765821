import React from "react";

function Nav() {

  return (
    <div className="bottom-nav" >
    <ul className="fix-nav" id="menu">
     <li className="nav-item"><a className="nav-link" href="#portfolio"> <i class="bi bi-laptop"></i> </a></li>
     <li className="nav-item"><a className="nav-link" href="#about"> <i class="bi bi-person"></i> </a></li>
     <li className="nav-item"><a className="nav-link" href="#home"> <i class="bi bi-house"></i> </a></li>
     <li className="nav-item"><a className="nav-link" href="#skill"> <i class="bi bi-trophy"></i> </a></li>
     <li className="nav-item"><a className="nav-link" href="#contact"> <i class="bi bi-chat-left-dots"></i> </a></li>
    </ul>
    </div>
  );
}
export default Nav;


