import React from 'react';
import Datemonth from './Date';

function Contact() {

 const alertmsg = () => {
    alert("Sorry! please try other options.");
 }

  return (
    <div id="contact">


<div className='heading'>
    <h2>Contact</h2>
    <span>Get In Touch<hr /></span>
 </div>

<div className='container contact'>
 <div className='row'>  
<div className='col-md-7'>
  <h2>Give me a shout</h2>
<div>
  <p>Want to get in touch?<br />I'd love to hear from you. Here's how you can reach me...</p>
  <form id="mailform" name="mailform">
    <div className="form-group">
            <div className="input-effect">
        	<input className="txt-box inputstyle" type="text" placeholder="" id="name" name="name" required="required"/>
            <label>Full Name</label>
            <span className="focus-border"></span>
            </div>
    </div>
    <div className="form-group">
            <div className="input-effect">
        	<input className="txt-box inputstyle" type="email" placeholder="" id="email" name="email" required="required"/>
            <label>Email Address</label>
            <span className="focus-border"></span>
            </div>
    </div>
    <div className="form-group">
              <div className="input-effect">
        	<textarea className="msg-box inputstyle" placeholder="" id="message" name="message" required="required"></textarea>
            <label>Hello! random citizen...</label>
            <span className="focus-border">
            	<i></i>
            </span>
            </div>
    </div>
    
    <button className="mybtn" type="submit" id="sendemail" name="sendemail" onClick={alertmsg} >Send</button>
</form>
</div>
</div>

<div className="col-md-5 col-sm-12 col-xs-12 text-center">
  <div className='date-div' data-aos="fade-up">
    <div className='date'>
    <Datemonth />
    </div>
    <p>Available For Work</p>
  </div>
  <div className='social'>
    <a href='https://m.me/y.prem.singha.page' target='_blank' data-aos="zoom-in">
     <svg xmlns="http://www.w3.org/2000/svg" width="28" height="28" fill="currentColor" class="bi bi-messenger" viewBox="0 0 16 16">
     <path d="M0 7.76C0 3.301 3.493 0 8 0s8 3.301 8 7.76-3.493 7.76-8 7.76c-.81 0-1.586-.107-2.316-.307a.639.639 0 0 0-.427.03l-1.588.702a.64.64 0 0 1-.898-.566l-.044-1.423a.639.639 0 0 0-.215-.456C.956 12.108 0 10.092 0 7.76zm5.546-1.459-2.35 3.728c-.225.358.214.761.551.506l2.525-1.916a.48.48 0 0 1 .578-.002l1.869 1.402a1.2 1.2 0 0 0 1.735-.32l2.35-3.728c.226-.358-.214-.761-.551-.506L9.728 7.381a.48.48 0 0 1-.578.002L7.281 5.98a1.2 1.2 0 0 0-1.735.32z"/>
     </svg>
      <h4>Facebook</h4>
      Send a message
    </a>

    <a href='https://wa.me/+64223226330' target='_blank' data-aos="zoom-in">
      <i class="bi bi-whatsapp"></i>
      <h4>WhatsApp</h4>
      Send a message
    </a>

  </div>
</div>

 </div>
    

</div>

<footer>
    Created with <span>❤</span>
</footer>
  </div>
  )
}

export default Contact