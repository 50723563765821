
import React, {useState, useEffect} from "react";
import Logo from './Images/logo.png';

function App() {

// parallax scroll
  const [offsetY, setOffsetY] = useState(-50);
  const handleScroll = () => setOffsetY(window.pageYOffset);
  useEffect(() => {
    window.addEventListener("scroll", handleScroll);
    return () => window.removeEventListener("scroll", handleScroll);
  }, []);
// parallax scroll end

  return (
    <div className="App home" id="home">
      <header>
      <nav className="navbar navbar-expand-sm navbar-dark">
          
          <a className="navbar-brand" href="/"><img src={Logo} alt="logo" /></a>
        
  
  
         {/*Links */}
    <div className="" id="collapsibleNavbar">
          <ul className="navbar-nav ml-auto">
            <li className="nav-item">
              <a className="nav-link" href="https://www.linkedin.com/in/ypremsingha/"> <i class="bi bi-linkedin"></i> </a>
            </li>
            <li className="nav-item">
              <a className="nav-link" href="https://github.com/ypremsingha" target="_blank"> <i class="bi bi-github"></i> </a>
            </li>
            <li className="nav-item">
              <a className="nav-link" href="https://www.instagram.com/ypremsingha/" target="_blank"> <i class="bi bi-instagram"></i> </a>
            </li>
            <li className="nav-item">
              <a className="nav-link cv-btn mybtn" href="http://prem.nz/PremSingha(CV).pdf" target="_blank"> Download CV </a>
            </li>
          </ul>
          </div>
        </nav>
      </header>

     <div className="container">
       <div className="row">
         <div className="myname">
          <div className="home-text" style={{ transform: `translateY(${offsetY * 0.33}px)` }}>
          <h1 data-aos="fade-up">Prem Singha</h1>
          <span data-aos="fade-down" data-aos-easing="ease" data-aos-delay="400">front-end developer & ui/ux designer</span>
          </div>
       
       {/* <div className="mailme">
         <a href="mailto:hello@prem.nz" target="new" class="mybtn"><svg xmlns="http://www.w3.org/2000/svg" width="22" height="22" fill="currentColor" class="bi bi-envelope" viewBox="0 0 16 16">
  <path d="M0 4a2 2 0 0 1 2-2h12a2 2 0 0 1 2 2v8a2 2 0 0 1-2 2H2a2 2 0 0 1-2-2V4Zm2-1a1 1 0 0 0-1 1v.217l7 4.2 7-4.2V4a1 1 0 0 0-1-1H2Zm13 2.383-4.708 2.825L15 11.105V5.383Zm-.034 6.876-5.64-3.471L8 9.583l-1.326-.795-5.64 3.47A1 1 0 0 0 2 13h12a1 1 0 0 0 .966-.741ZM1 11.105l4.708-2.897L1 5.383v5.722Z"/>
</svg> <span>hello@prem.nz</span></a>
       </div> */}

         </div>
       </div>
     </div>
    </div>
  );
}
export default App;

