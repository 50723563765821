import React from 'react';
import cert1 from '../Images/UXUI_Certification.jpg';
import html from '../Images/skills/html.png';
import css from '../Images/skills/css.png';
import js from '../Images/skills/js.png';
import bootstrap from '../Images/skills/bootstrap.png';
import ps from '../Images/skills/ps.png';
import dw from '../Images/skills/dw.png';
import vs from '../Images/skills/vs.png';
import wordpress from '../Images/skills/wordpress.png';
import react from '../Images/skills/react.png';
import figma from '../Images/skills/figma.png';
import windows from '../Images/skills/windows.png';
import mac from '../Images/skills/mac.png';

function Skills() {
  return (
<div id="skill">

 <div className='heading'>
    <h2>SKILLS</h2>
    <span>What I Do<hr /></span>
  </div>

  <div className='container'>
 <div className='row about'> 

 <div className="col-md-5 col-xs-12">
   


    {/* <h2>Experiences</h2> */}
    <div class="timeline-holder">
                    <div className="timeline-head event-head">
                      <div className="icon">
                      <i class="bi bi-briefcase"></i>
                      </div>
                    </div>
                    <div className="timeline-body">
                      <div className="event-item timeline-item">

                        <div className="event-title left">
                          <h2>SupportNxt</h2>
                        </div>
                        <div className="event-content timeline-content right">

                          <h4>Sr. Web Designer</h4>
                          <p>
                           Aug 2018 - Present
                          </p>
                        </div>
                      </div>


                      <div className="event-item timeline-item">
                        <div className="event-content timeline-content left">

                          <h4>UX/UI Designer</h4>
                          <p>
                            Feb 2016 - July 2018
                          </p>
                        </div>
                            <div className="event-title right">
                          <h2>Om Software Pvt. Ltd</h2>
                        </div>

                      </div>


                      <div className="event-item timeline-item">

                        <div className="event-title left">
                          <h2>Easy Media Pvt. Ltd</h2>
                        </div>

                        <div className='event-content timeline-content right'>
                          <h4>Web Designer</h4>
                          <p>
                            July 2013 - Jan 2016
                          </p>

                      </div>

                    </div>
                  </div>
    </div>


    <div>
        <h2>Certification</h2>
        <div className='certificate'  data-aos="fade-up">
            <a href="/"><img src={cert1} alt="certificate" /></a>
        </div>
    </div>

</div>

<div className='col-md-7'>
  <h2>Designing professionally for over six years</h2>
 <div className='my-info'>
  <p>A professional web designer with over <em>6 years</em> of experience working with small to medium enterprises in New Zealand
and India. A developer with vast expertise on front-end development involving <em>HTML</em>, <em>CSS</em>, <em>Bootstrap</em>, <em>JavaScript</em>, <em>React</em>,
and coding interactive layouts; along with commercial and open-source CMS like <em>WordPress</em>, <em>Shopify</em> and various other
platforms for full-stack development.</p>
  </div>

  <div>
  <h3>Technologies</h3>
  <div className='skill-icons'>
      <div className='icons' data-aos="zoom-in">
          <img src={html} alt="skills" />
      </div>
      <div className='icons' data-aos="zoom-in">
          <img src={css} alt="skills" />
      </div>
      <div className='icons' data-aos="zoom-in">
          <img src={js} alt="skills" />
      </div>
      <div className='icons' data-aos="zoom-in">
          <img src={react} alt="skills" />
      </div>
      <div className='icons' data-aos="zoom-in">
          <img src={bootstrap} alt="skills" />
      </div>
      <div className='icons' data-aos="zoom-in">
          <img src={wordpress} alt="skills" />
      </div>
  </div>
  </div>

  <div>
  <h3>Tools</h3>
  <div className='skill-icons'>
      <div className='icons' data-aos="zoom-in">
          <img src={ps} alt="skills" />
      </div>
      <div className='icons' data-aos="zoom-in">
          <img src={figma} alt="skills" />
      </div>
      <div className='icons' data-aos="zoom-in">
          <img src={vs} alt="skills" />
      </div>
      <div className='icons' data-aos="zoom-in">
          <img src={dw} alt="skills" />
      </div>
  </div>
  </div>

  <div>
  <h3>Operating system</h3>
  <div className='skill-icons'>
      <div className='icons' data-aos="zoom-in">
          <img src={windows} alt="skills" />
      </div>
      <div className='icons' data-aos="zoom-in">
          <img src={mac} alt="skills" />
      </div>
  </div>
  </div>


</div>


 </div>
    

</div>

</div>
  )
}

export default Skills