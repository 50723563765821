import React from 'react';
import ReactDOM from 'react-dom';
import './Styles/main.css';
import './Styles/mobile.css';
import Nav from './Components/Nav';
import App from './App';
import Work from './Components/Work'
import About from './Components/About';
import Skills from './Components/Skills';
import Contact from './Components/Contact';

ReactDOM.render(
  <React.StrictMode>
    <Nav />
    <App />
    <hr />
    <Work />
    <hr />
    <About />
    <hr />
    <Skills />
    <hr />
    <Contact />
  </React.StrictMode>,
  document.getElementById('root')
);

