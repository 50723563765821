import React from 'react';
import work1 from '../Images/work/foodannie_app.jpg';
import work2 from '../Images/work/bitdoji.png';
import work3 from '../Images/work/mind_app.jpg';
import work4 from '../Images/work/tuseva.png';
import work5 from '../Images/work/wedding.jpg';
import work6 from '../Images/work/lukbiz.png';
import work7 from '../Images/work/nid.jpg';
import work8 from '../Images/work/sip.png';
import work9 from '../Images/work/zelute.png';


// Data 
const data = [
  {
    id: 1,
    image: work1,
    title: 'Food Annie',
    desc: 'Easy way to get the food you love delivered',
    link: 'https://play.google.com/store/apps/details?id=com.foodannie'
  },
  {
    id: 2,
    image: work2,
    title: 'bitdoji',
    desc: 'Buy and sell digital currency',
    link: 'https://prem.nz'
  },
  {
    id: 3,
    image: work3,
    title: 'Mind Quizzer',
    desc: 'Tool that will transform your education experience',
    link: 'https://play.google.com/store/apps/details?id=com.mindquizzer'
  },
  {
    id: 4,
    image: work4,
    title: 'Tuseva',
    desc: 'Help you choose perfect car',
    link: 'https://prem.nz'
  },
  {
    id: 5,
    image: work5,
    title: 'Wedding',
    desc: 'Helps plan your wedding like a loved one',
    link: 'https://prem.nz'
  },
  {
    id: 6,
    image: work6,
    title: 'Lukbiz',
    desc: 'Connecting local street markets of the city',
    link: 'https://prem.nz'
  },
  {
    id: 7,
    image: work7,
    title: 'Nidavellirs',
    desc: 'Best IT solutions provider agency',
    link: 'https://nidavellirs.com/'
  },
  {
    id: 8,
    image: work8,
    title: 'SIPtm',
    desc: 'Customized investments to turn dreams into reality',
    link: 'https://prem.nz'
  },
  {
    id: 9,
    image: work9,
    title: 'Zelute',
    desc: 'Design, Develop and Maintain web and mobile apps',
    link: 'https://prem.nz'
  },
]




function Work() {
  return <div>

<div className='container' id='portfolio'>
    <div className='heading'>
    <h2>Portfolio</h2>
    <span >Selected Works <hr /></span>
    </div>



   <div className='grids'>

     {
       data.map(({id, image, title, desc, link}) => {
         return (
          <a key={id} href={link} className='work' target='_blank' data-aos="fade-up">
          <div className='img-box'>
          <div className='img'>
           <img src={image} alt="work" />
          </div>
          </div>
           <span>{title}</span>
           <h4>{desc}</h4>
          </a>
         )
       }
       
       )
     }

   </div>

</div>

  </div>;
}



export default Work;