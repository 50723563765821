import React from 'react'

    var Datemonth = () =>
    {
        var showdate = new Date();        
        var d = showdate.getDate();
        var m = showdate.getMonth();
        var months = ["Jan","Feb","Mar","Apr","May","Jun","Jul","Aug","Sep","Oct","Nov","Dec"];
        var todaysmonth = months[m];
  return (
   <span>{d} {todaysmonth}</span> 
  );
}

export default Datemonth;